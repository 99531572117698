import { Table } from "antd";
import React, { useEffect, useState } from "react";  

export default function TableUser({data}) {

  const [filterName, setFilterName] = useState("")
  const [filterRole, setFilterRole] = useState("")

  useEffect(() => {
  
    if(data) {
      let arrayFilterName = []
      let arrayFilterRole = []

      const uniqueArray = data.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.name === obj.name
        ))
      );
  
      const uniqueArrayRole = data.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.role === obj.role
        ))
      );
  
      uniqueArray.forEach(element => {
        let data1 = {
          text: `${element.name} ${element.lastName}`,
          value: element.name
        }
  
        arrayFilterName.push(data1)
      });
  
      uniqueArrayRole.forEach(element => {
        let data2 = {
          text: element.role,
          value: element.role
        }
  
        arrayFilterRole.push(data2)
      });
  
      setFilterName(arrayFilterName)
      setFilterRole(arrayFilterRole)
    }
    
  }, [data])

  console.log(data)

    const columns = [
        {
          title: 'Nome',
          dataIndex: 'name',
          showSorterTooltip: {
            target: 'full-header',
          },
          filters: filterName,
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ['descend'],
          render: (text, record) => `${record.name} ${record.lastName}`
        },
        {
          title: 'Papel',
          dataIndex: 'role',
          defaultSortOrder: 'descend',

          filters:filterRole,
          onFilter: (value, record) => record.role.indexOf(value) === 0,
          sorter: (a, b) => a.role - b.role,
        },
        {
          title: 'E-mail',
          dataIndex: 'email',          
          onFilter: (value, record) => record.address.indexOf(value) === 0,
        },
      ];

      const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
      };

    return(
        
        <Table
        columns={columns}
        dataSource={data}
        onChange={onChange}
        showSorterTooltip={{
        target: 'sorter-icon',
        }}
        style={{width: "100%"}}
        />
        
    )
}