import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    height: 100vh;
`
export const ContainerLogin = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
`
export const LoginPicture = styled.img`
    height: 100%;
    width: 100%;
    position: absolute;
`

export const CardLogin = styled.div`
    display: flex;
    height: 80%;
    width: ${(props) => props.mobile ? "90%" : "30%"};
    justify-content: center;
    background-color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 2;
`
export const HeaderLoginCard = styled.div`
    background-color: #34C576;
    width: 100%;
    height: 75px;
    display: flex;
`
export const ColumnContainer = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
`;
export const RowContainer = styled.div`
  flex-direction: row;
  display: flex;
`;

export const TextDefault = styled.text`
  color: ${(props) => (props.color ? props.color : "#000")};
  font-size: ${(props) => (props.size ? props.size : "15px")};
  font-weight: ${(props) => (props.bold ? props.bold : "bold")};
`;

export const GroupInput = styled.div`
  width: 100%;
  margin-top: 20px;
`;
export const AttCircule = styled.div`
  width: 50px;
  height: 50px;
  background-color: #f4f4f4;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    background-color: #eaeaea;
    cursor: pointer;
    
  }
`;

export const ImgOpt = styled.img`
  width: ${(props) => props.width || "16px"};
  height: ${(props) => props.height || "16px"};
`

export const InputDefault = styled.input`
  border-width: 2px;
  border-color: #F4F4F4;
  width: 100%;
`;

export const SelectDefault = styled.select`
  border-color: #F4F4F4;
  width: 100%;
  height: 50px;
  padding-right: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #4b4b4b;
`;
export const BtnAcesso = styled.button`
  border: none;
  background-color: #34C576;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: ${(props) => props.mobile ? "84%" : "80%"};
  height: 50px;
  bottom: 50px;
  position: absolute;

  &:hover {
    background-color: #2FB86D;
  }
`;
export const ContainerShortLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.mobile ? "84%" : "80%"};
  height: 50px;
  bottom: 100px;
  position: absolute;
`;
export const PainelCR1 = styled.div`
  display: flex;
  width: 95%;
  background-color: #F4F4F4;
  padding: 20px 40px 20px 40px;
`;

export const ContainerErroLogin = styled.div`
  width: 350px;
  height: 100px;
  position: fixed;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 9999;
`;
export const Bar = styled.div`
  width: 70px;
  height: 100%;
  background-color: #34C576;
  position: fixed;
  padding-top: 100px;
  z-index: 99;
  `;
export const BarMobile = styled.div`
  width: 100%;
  height: 80px;
  background-color: #34C576;
  position: fixed;
  bottom: 0;
  flex-direction: row;
  display: flex;
  z-index: 99;
`;
export const OpenBar = styled.div`
  width: 300px;
  margin-left: 70px;
  height: 100%;
  background-color: #4ADE8D;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  z-index: 90;
  overflow: auto;
  
`;
export const OpenBarMobile = styled.div`
  width: 100%;
  max-height: 70%;
  bottom: 70px;
  background-color: #4ADE8D;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  z-index: 90;
  overflow: auto;
  
`;
export const ContainerOptSide = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.openBar ? "#fff" : "transparent"};

  &:hover {
    background-color: #fff
  }

`;
export const ContainerOptSideMobile = styled.div`
  width: 80px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.openBar ? "#fff" : "transparent"};

  &:hover {
    background-color: #fff
  }

`;
export const ContaineroptMenu = styled.div`
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #34C576
  }

`;
export const RodapeTable = styled.div`
  width: ${(props) => props.mobile ? "103%" : "98%"} ;
  background-color: #34c576;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;

`;
export const ContainerModalUsers = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalUsers = styled.div`
  width: 70%;
  height: 50%;
  background-color: #fff;
  padding-left: ${(props) => props.dp ? "0" : "40px"};
  padding-top:  ${(props) => props.dp ? "0" : "20px"};;
  z-index: 99;
  
  @media screen and (max-width: 1308px) {
    height: 70%;
  }

`;

export const ImgDefault = styled.img`
  width: ${(props) => (props.width ? props.width : "50px")};
  height: ${(props) => (props.height ? props.height : "50px")};
  
`;

export const BtnAddUsers = styled.button`
  border: none;
  background-color: #34C576;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: ${(props) => props.mobile ? "50%" : "10%"};
  height: ${(props) => props.mobile ? "55px" : "50px"};

  &:hover {
    background-color: #2FB86D;
  }
`;
export const BtnCancelUser = styled.button`
  border: none;
  background-color: #F17373;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px 10px 20px;

  &:hover {
    background-color: #F85B5B;
  }
`;
export const BtnSaveUser = styled.button`
  border: none;
  background-color: #34C576;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px 10px 20px;

  &:hover {
    background-color: #2FB86D;
  }
`;