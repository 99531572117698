import React, { useEffect, useState } from "react";
import { AttCircule, ColumnContainer, Container, GroupInput, ImgDefault, InputDefault, RowContainer, SelectDefault, TextDefault } from "../../styles";
import SidebarMobile from "../../components/sidebar/mobile";
import Sidebar from "../../components/sidebar";
import { Grid } from "@mui/material";

export default function RegistroNorma() {

    const [isMobile, setIsMobile] = useState("")
    const [isTypeDoc, setIsTypeDoc] = useState("")
    const [isID, setIsID] = useState("")
    const [isVersion, setIsVersion] = useState("")
    const [isOrgResp, setIsOrgResp] = useState("")
    const [isEmissao, setIsEmissao] = useState("")
    const [isTitulo, setIsTitulo] = useState("")
    const [isEscopo, setIsEscopo] = useState("")

    useEffect(() => {
        const TitlePage = "Resgistro da Norma";
        document.title = TitlePage;

        setIsMobile(/Mobi/.test(navigator.userAgent));
    }, []);

    return(
        <Container>
            {isMobile ? (<SidebarMobile/>) : (<Sidebar/>)}


            <ColumnContainer style={{paddingLeft: isMobile ? 20 : 120, paddingTop: 30, paddingRight: 40, width: "100%", height: "65%"}}>
                <RowContainer style={{justifyContent: "space-between", alignItems: "center", width: "99%"}}>
                    <TextDefault size="40px" color="#34C576" bold="800" >Cadastro da Norma</TextDefault>
                    
                    <RowContainer style={{alignItems: "center", paddingRight: 50}}>
                        <AttCircule>
                            <ImgDefault src={require("../../picture/attach.png")} height="20px" width="20px"/>                        
                        </AttCircule>                                        
                    </RowContainer>
                </RowContainer>

                <TextDefault size="25px" color="#282828" style={{marginTop: 40}}>Cabeçalho da Norma</TextDefault>

                <Grid container xs={12} sm={12} md={12} lg={12} style={{marginTop: 10}}>
                                    
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                        <TextDefault size={isMobile ? "13px" : "15px"} color="#8a97aa" bold="600" style={{marginBottom: -15}}>Tipo de Documento</TextDefault>
                        <GroupInput className="groupInputSale" style={{marginTop: 5}}>
                            <SelectDefault
                                className="inputSale"
                                placeholder="Tipo de Documento"
                                type="text"
                                id="typeDoc"
                                value={isTypeDoc}
                                style={{ paddingLeft: 15, width: "88%" }}
                                onChange={(item) => setIsTypeDoc(item.target.value)}
                            >
                                <option value="">Tipo de Documento</option>
                            </SelectDefault>                        
                        </GroupInput>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={4}>
                        <TextDefault size={isMobile ? "13px" : "15px"} color="#8a97aa" bold="600" style={{marginBottom: -15}}>Indentificador</TextDefault>
                        <GroupInput className="groupInputSale" style={{marginTop: 5}}>
                            <InputDefault
                                className="inputSale"
                                placeholder="Indentificador"
                                type="text"
                                id="id"
                                value={isID}
                                style={{ paddingLeft: 15, width: "85%" }}
                                onChange={(item) => setIsID(item.target.value)}
                            />                        
                        </GroupInput>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={4}>
                        <TextDefault size={isMobile ? "13px" : "15px"} color="#8a97aa" bold="600" style={{marginBottom: -15}}>Revisão/Versão</TextDefault>
                        <GroupInput className="groupInputSale" style={{marginTop: 5}}>
                            <InputDefault
                                className="inputSale"
                                placeholder="Revisão/Versão"
                                type="text"
                                id="rv"
                                value={isVersion}
                                style={{ paddingLeft: 15, width: "85%" }}
                                onChange={(item) => setIsVersion(item.target.value)}
                            />                        
                        </GroupInput>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={4}>
                        <TextDefault size={isMobile ? "13px" : "15px"} color="#8a97aa" bold="600" style={{marginBottom: -15}}>Organismo Responsável</TextDefault>
                        <GroupInput className="groupInputSale" style={{marginTop: 5}}>
                            <InputDefault
                                className="inputSale"
                                placeholder="Organismo Responsável"
                                type="text"
                                id="organismo"
                                value={isOrgResp}
                                style={{ paddingLeft: 15, width: "85%" }}
                                onChange={(item) => setIsOrgResp(item.target.value)}
                            />                        
                        </GroupInput>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={4}>
                        <TextDefault size={isMobile ? "13px" : "15px"} color="#8a97aa" bold="600" style={{marginBottom: -15}}>Data de Emissão</TextDefault>
                        <GroupInput className="groupInputSale" style={{marginTop: 5}}>
                            <InputDefault
                                className="inputSale"
                                placeholder="Data de Emissão"
                                type="text"
                                id="emissao"
                                value={isEmissao}
                                style={{ paddingLeft: 15, width: "85%" }}
                                onChange={(item) => setIsEmissao(item.target.value)}
                            />                        
                        </GroupInput>
                    </Grid>

                </Grid>

                <TextDefault size="25px" color="#282828" style={{marginTop: 40}}>Informações da Norma</TextDefault>

                <Grid container xs={12} sm={12} md={12} lg={12} style={{marginTop: 10}}>
                                    
                    

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextDefault size={isMobile ? "13px" : "15px"} color="#8a97aa" bold="600" style={{marginBottom: -15}}>Título/Descrição da Norma</TextDefault>
                        <GroupInput className="groupInputSale" style={{marginTop: 5}}>
                            <InputDefault
                                className="inputSale"
                                placeholder="Título/Descrição da Norma"
                                type="text"
                                id="titulo"
                                value={isTitulo}
                                style={{ paddingLeft: 15, width: "95%" }}
                                onChange={(item) => setIsTitulo(item.target.value)}
                            />                        
                        </GroupInput>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextDefault size={isMobile ? "13px" : "15px"} color="#8a97aa" bold="600" style={{marginBottom: -15}}>Resumo do Escopo da Norma</TextDefault>
                        <GroupInput className="groupInputSale" style={{marginTop: 5}}>
                            <InputDefault
                                className="inputSale"
                                placeholder="Resumo do Escopo da Norma"
                                type="text"
                                id="escopo"
                                value={isEscopo}
                                style={{ paddingLeft: 15, width: "95%" }}
                                onChange={(item) => setIsEscopo(item.target.value)}
                            />                        
                        </GroupInput>
                    </Grid>
                    

                </Grid>


            </ColumnContainer>

        </Container>

    )
}