import React, { useEffect, useState } from "react";
import { ColumnContainer, Container, ImgDefault, RowContainer, TextDefault } from "../../styles";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar";
import AgoLogo from "../../picture/logo-agrobiotech.png"
import SidebarMobile from "../../components/sidebar/mobile";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
        

export default function Home() {

    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const TitlePage = "Início";
        document.title = TitlePage;

        setIsMobile(/Mobi/.test(navigator.userAgent));
    }, []);


    return(
        <Container>
            {isMobile ? (<SidebarMobile/>) : (<Sidebar/>)}
            <ImgDefault src={AgoLogo} style={{position: "absolute", left: isMobile ? "18%" : 85, top: 10}} width={isMobile ? "65%" : "15%"} height="90px"/>
            
            <ColumnContainer style={{marginTop: isMobile ? 120 : 150, marginLeft: isMobile ? 40 : 100, zIndex: 3, width: "100%"}}>
                <RowContainer style={{ width: "100%"}}>
                
                    <ColumnContainer style={{width: isMobile ? "90%" : "20%", marginRight: isMobile ? 0 : 90}}>
                        <div style={{width: "100%"}}>
                            <Skeleton count={1} height={"150px"} />
                        </div>               
                        <div style={{width: "30%", marginTop: 10}}>
                            <Skeleton count={1} />
                        </div>               
                        <div style={{width: "100%"}}>
                            <Skeleton count={2} />
                        </div>               
                    </ColumnContainer>

                    {isMobile ? "" : (
                        <>
                            <ColumnContainer style={{width: "20%", marginRight: 90}}>
                                <div style={{width: "100%"}}>
                                    <Skeleton count={1} height={"150px"} />
                                </div>               
                                <div style={{width: "30%", marginTop: 10}}>
                                    <Skeleton count={1} />
                                </div>               
                                <div style={{width: "100%"}}>
                                    <Skeleton count={2} />
                                </div>               
                            </ColumnContainer>

                            <ColumnContainer style={{width: "20%", marginRight: 90}}>
                                <div style={{width: "100%"}}>
                                    <Skeleton count={1} height={"150px"} />
                                </div>               
                                <div style={{width: "30%", marginTop: 10}}>
                                    <Skeleton count={1} />
                                </div>               
                                <div style={{width: "100%"}}>
                                    <Skeleton count={2} />
                                </div>               
                            </ColumnContainer>

                            <ColumnContainer style={{width: "20%"}}>
                                <div style={{width: "100%"}}>
                                    <Skeleton count={1} height={"150px"} />
                                </div>               
                                <div style={{width: "30%", marginTop: 10}}>
                                    <Skeleton count={1} />
                                </div>               
                                <div style={{width: "100%"}}>
                                    <Skeleton count={2} />
                                </div>               
                            </ColumnContainer>
                        </>
                    )}

                                
                </RowContainer>

                <RowContainer style={{width: "100%", marginTop: isMobile ? 50 : 100}}>
                    <div style={{width: "15%", marginRight: isMobile ? 15 : 50}}>
                        <Skeleton count={1} />
                    </div> 
                    <div style={{width: "50%", marginRight: isMobile ? 15 : 50}}>
                        <Skeleton count={1} />
                    </div> 
                    <div style={{width: isMobile ? "16.3%" : "24.5%"}}>
                        <Skeleton count={1} />
                    </div> 
                </RowContainer>
                <RowContainer style={{width: "100%", marginTop: 20}}>
                    <div style={{width: "15%", marginRight: isMobile ? 15 : 50}}>
                        <Skeleton count={1} />
                    </div> 
                    <div style={{width: "50%", marginRight: isMobile ? 15 : 50}}>
                        <Skeleton count={1} />
                    </div> 
                    <div style={{width: isMobile ? "16.3%" : "24.5%"}}>
                        <Skeleton count={1} />
                    </div> 
                </RowContainer>
                <RowContainer style={{width: "100%", marginTop: 20}}>
                    <div style={{width: "15%", marginRight: isMobile ? 15 : 50}}>
                        <Skeleton count={1} />
                    </div> 
                    <div style={{width: "50%", marginRight: isMobile ? 15 : 50}}>
                        <Skeleton count={1} />
                    </div> 
                    <div style={{width: isMobile ? "16.3%" : "24.5%"}}>
                        <Skeleton count={1} />
                    </div> 
                </RowContainer>
                <RowContainer style={{width: "100%", marginTop: 20}}>
                    <div style={{width: "15%", marginRight: isMobile ? 15 : 50}}>
                        <Skeleton count={1} />
                    </div> 
                    <div style={{width: "50%", marginRight: isMobile ? 15 : 50}}>
                        <Skeleton count={1} />
                    </div> 
                    <div style={{width: isMobile ? "16.3%" : "24.5%"}}>
                        <Skeleton count={1} />
                    </div> 
                </RowContainer>
                <RowContainer style={{width: "100%", marginTop: 20}}>
                    <div style={{width: "15%", marginRight: isMobile ? 15 : 50}}>
                        <Skeleton count={1} />
                    </div> 
                    <div style={{width: "50%", marginRight: isMobile ? 15 : 50}}>
                        <Skeleton count={1} />
                    </div> 
                    <div style={{width: isMobile ? "16.3%" : "24.5%"}}>
                        <Skeleton count={1} />
                    </div> 
                </RowContainer>

                
                {isMobile ? "" : (
                    <>
                        <RowContainer style={{width: "100%", marginTop: 20}}>
                            <div style={{width: "15%", marginRight: 50}}>
                                <Skeleton count={1} />
                            </div> 
                            <div style={{width: "50%", marginRight: 50}}>
                                <Skeleton count={1} />
                            </div> 
                            <div style={{width: "24.5%"}}>
                                <Skeleton count={1} />
                            </div> 
                        </RowContainer>
                        <RowContainer style={{width: "100%", marginTop: 20}}>
                            <div style={{width: "15%", marginRight: 50}}>
                                <Skeleton count={1} />
                            </div> 
                            <div style={{width: "50%", marginRight: 50}}>
                                <Skeleton count={1} />
                            </div> 
                            <div style={{width: "24.5%"}}>
                                <Skeleton count={1} />
                            </div> 
                        </RowContainer>
                        <RowContainer style={{width: "100%", marginTop: 20}}>
                            <div style={{width: "15%", marginRight: 50}}>
                                <Skeleton count={1} />
                            </div> 
                            <div style={{width: "50%", marginRight: 50}}>
                                <Skeleton count={1} />
                            </div> 
                            <div style={{width: "24.5%"}}>
                                <Skeleton count={1} />
                            </div> 
                        </RowContainer>
                        <RowContainer style={{width: "100%", marginTop: 20}}>
                            <div style={{width: "15%", marginRight: 50}}>
                                <Skeleton count={1} />
                            </div> 
                            <div style={{width: "50%", marginRight: 50}}>
                                <Skeleton count={1} />
                            </div> 
                            <div style={{width: "24.5%"}}>
                                <Skeleton count={1} />
                            </div> 
                        </RowContainer>
                        <RowContainer style={{width: "100%", marginTop: 20}}>
                            <div style={{width: "15%", marginRight: 50}}>
                                <Skeleton count={1} />
                            </div> 
                            <div style={{width: "50%", marginRight: 50}}>
                                <Skeleton count={1} />
                            </div> 
                            <div style={{width: "24.5%"}}>
                                <Skeleton count={1} />
                            </div> 
                        </RowContainer>
                    </>
                )}

            
            </ColumnContainer>
  

        

        </Container>
    )
}