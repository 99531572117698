import './App.css';
import { AppRoutes } from './routes/Routes';

function App() {
  return (
    <AppRoutes/>
  );
}

export default App;
