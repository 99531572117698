import { auth, database } from "../database/config";

export const signIn = async (email, pass) => {
    return new Promise(async (resolve, reject) => {
        await auth
        .signInWithEmailAndPassword(email, pass)
        .then((value) => {
            resolve("Login bem sucedido " + value);
        })
        .catch((err) => {
            reject("Erro ao se conectar: " + err);
        });
    });
};

export const logout = async () => {
    await auth.signOut();
};

export const authScreen = () => {
    return new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
        if (user) {
            resolve(true);
        } else {
            resolve(false);
        }
        });
    });
};