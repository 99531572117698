import React, { useEffect, useState } from "react";
import { BtnAcesso, CardLogin, ColumnContainer, ContainerErroLogin, ContainerLogin, ContainerShortLoading, GroupInput, HeaderLoginCard, ImgOpt, InputDefault, LoginPicture, RowContainer, TextDefault } from "../../styles";
import BackgroundLogin from "../../picture/backgroud-agro.jpg"
import EmailIcon from "../../picture/userLogin.png"
import HidenIcon from "../../picture/hidden.png"
import VisibleIcon from "../../picture/visible.png"
import { signIn } from "../../contexts/auth";
import { useNavigate } from "react-router-dom";
import Warning from "../../picture/cancel.png"

export default function Login() {

    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("")
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [errorLogin, setErrorLogin] = useState("erroStep0");
    const [errorLoginMgs, setErrorLoginMsg] = useState("");
    const [loadingState, setLoadingState] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        const TitlePage = "Login";
        document.title = TitlePage;

        setIsMobile(/Mobi/.test(navigator.userAgent));
    }, []);

    const login = () => {

        setLoadingState(true)
        
        if(email && pass) {
            signIn(email, pass).then(() => {
                navigate("/home")
                setLoadingState(false)
            })
            .catch((err) => {
                setErrorLoginMsg("E-mail ou senha incorretos!")
                setErrorLogin("erroStep1");
                setLoadingState(false)
                setTimeout(() => {
                    setErrorLogin("erroStep2");
                }, 5000);
            })
        } else {
            setLoadingState(false)
            setErrorLoginMsg("Preencha corretamente os campos para fazer seu acesso")
            setErrorLogin("erroStep1");
            setTimeout(() => {
                setErrorLogin("erroStep2");
            }, 5000);
        }
    }

    return(
       <ContainerLogin>

        <ContainerErroLogin className={errorLogin}>
            <div class="cardErroLogin">
            <img class="imgErroLogin" src={Warning} />
            <div class="textBoxErroLogin">
                <div class="textContentErroLogin">
                <p class="h1ErroLogin">Não foi Possivel conectar</p>
                <span class="spanErroLogin"></span>
                </div>
                <p class="pErroLogin">{errorLoginMgs}</p>
                <div></div>
            </div>
            </div>
        </ContainerErroLogin>

        <LoginPicture src={BackgroundLogin}/>
        
        <CardLogin mobile={isMobile}>
            <ColumnContainer style={{width: "100%" }}>
                <HeaderLoginCard/>
                <ColumnContainer style={{paddingLeft: isMobile ? 35 : 50, paddingTop: 20, height: "100%"}}>
                    <RowContainer>
                        <TextDefault size={isMobile ? "25px" : "40px"} color="#282828" bold="800">Bem-vindo ao</TextDefault>
                        <TextDefault size={isMobile ? "25px" : "40px"} color="#34C576" bold="800" style={{marginLeft: isMobile ? 10 : 15}}>Login</TextDefault>
                    </RowContainer>
                    <TextDefault size={isMobile ? "14px" : "18px"} color="#282828" bold="300" style={{width: "80%", marginTop: 20}}>Para acessar sua plataforma de gestão de qualidade, basta fazer login com suas credenciais de usuário.</TextDefault>

                    <TextDefault size={isMobile ? "13px" : "15px"} color="#282828" bold="300" style={{width: "80%", marginTop: isMobile ? 20 : 90, marginBottom: -15}}>E-mail</TextDefault>
                    <GroupInput className="groupInputSale">
                        <InputDefault
                            className="inputSale"                            
                            type="email"
                            id="usuario"
                            value={email}
                            style={{ paddingLeft: 15, width: "85%" }}
                            onChange={(item) => setEmail(item.target.value)}
                        />
                        <ImgOpt className="iconInputHide" src={EmailIcon} style={{width: 27, height: 27}}/>
                    </GroupInput>

                    <TextDefault size={isMobile ? "13px" : "15px"} color="#282828" bold="300" style={{width: "80%", marginTop: 20, marginBottom: -15}}>Senha</TextDefault>
                    <GroupInput className="groupInputSale">
                        <InputDefault
                            className="inputSale"                            
                            type={isPasswordVisible ? "text" : "password"}
                            id="usuario"
                            value={pass}
                            style={{ paddingLeft: 15, width: "85%" }}
                            onChange={(item) => setPass(item.target.value)}
                        />

                        {isPasswordVisible ? (                            
                            <ImgOpt
                            className="iconInputHide"
                            src={VisibleIcon}
                            onClick={() => setIsPasswordVisible(false)}
                            style={{width: 27, height: 27}}
                            />
                        ) : (
                            <ImgOpt
                            className="iconInputHide"
                            src={HidenIcon}
                            onClick={() => setIsPasswordVisible(true)}
                            style={{width: 27, height: 27}}
                            />
                        )}
                        
                    </GroupInput>

                    <TextDefault className="linkText" size={isMobile ? "13px" : "15px"} color="#282828" bold="300" style={{width: "80%", marginTop: 30}}>Esqueceu a Senha?</TextDefault>

                    {loadingState ? (
                        <ContainerShortLoading>                        
                            <div class="loader">
                                <span class="loader-text">loading</span>
                                <span class="load"></span>
                            </div>
                        </ContainerShortLoading>                      

                    ) : (
                        <BtnAcesso mobile={isMobile} onClick={() => login()}>
                            <TextDefault size="25px" color="#fff" bold="800">Acessar</TextDefault>
                        </BtnAcesso>
                    )}

                </ColumnContainer>
            </ColumnContainer>     
        </CardLogin>

       </ContainerLogin>
    )
}