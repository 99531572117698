import React, { useEffect, useState } from "react";
import { BtnAddUsers, BtnCancelUser, BtnSaveUser, ColumnContainer, Container, ContainerModalUsers, GroupInput, ImgDefault, ImgOpt, InputDefault, ModalUsers, RodapeTable, RowContainer, SelectDefault, TextDefault } from "../../styles";
import SidebarMobile from "../../components/sidebar/mobile";
import Sidebar from "../../components/sidebar";
import Lupa from "../../picture/search.png"
import UserIcon from "../../picture/user.png"
import TableUser from "./components/table";
import { Grid, Modal } from "@mui/material";
import CloseIcon from "../../picture/close.png"
import { getUsers, registerUser } from "../../hooks/getUsers";

export default function UsersControl() {

    const [isMobile, setIsMobile] = useState(false)
    const [search, setSearch] = useState("")
    const [isModal, setIsModal] = useState(false)
    const [isAddUser, setIsAddUser] = useState(true)
    const [name, setName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [role, setRole] = useState("")
    const [sector, setSector] = useState("") 
    const [userList, setUserList] = useState("") 
    const [displayPassword, setDisplayPassword] = useState(false) 
    const [createPassword, setCreatePassword] = useState("") 

    useEffect(() => {
        const TitlePage = "Gestão de Usuários";
        document.title = TitlePage;

        setIsMobile(/Mobi/.test(navigator.userAgent));


        getUsers().then(resp => {            
            setUserList(resp)
        })

    }, []);

    const openModal = (state) => {
        if(state == 0) {
            setIsAddUser(true)
            setIsModal(true)
        } else {
            setIsAddUser(false)
            setIsModal(true)

        }
    }

    const closeModal = () => {
        setIsModal(false)
    }

    const createUser = () => {
        if(name&&lastName&&email&&role&&sector) {                    
            registerUser({name, lastName, email, role, sector}).then(resp => {
                setCreatePassword(resp)
                setDisplayPassword(true)
            })
        } else {
            console.log("Preenchar corretamente")
        }
    }

    const finalyRegister = () => {
        setCreatePassword("")
        setDisplayPassword(false)
        setName("")
        setLastName("")
        setEmail("")
        setRole("")
        setSector("")
        setIsModal(false)
        getUsers().then(resp => {            
            setUserList(resp)
        })
    }

    return(
        <Container>
            {isMobile ? (<SidebarMobile/>) : (<Sidebar/>) /**Componente de sidebar para web e mobile */}

            <Modal
              open={isModal}
              onClose={() => closeModal()}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >

                <ContainerModalUsers>
                    <ModalUsers dp={displayPassword}>

                        {displayPassword ? (
                            <ColumnContainer style={{position: "relative", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                                <TextDefault color="#282828" size="35px">Anote a senha provisória do usuário criado</TextDefault>
                                <TextDefault color="#ABABAB" size="14px" style={{marginTop: 20}}>Essa senha deve ser alterada após o primeiro acesso do usuário</TextDefault>
                                <TextDefault color="#ABABAB" size="25px" bold="800" style={{marginTop: 20}}>{createPassword}</TextDefault>

                                <BtnSaveUser style={{marginTop: 30}} onClick={finalyRegister}>
                                        <TextDefault size="19px" color="#fff" bold="700">Concluir</TextDefault>
                                </BtnSaveUser>
                            </ColumnContainer>
                        ) : (

                            <ColumnContainer>
                                <RowContainer style={{justifyContent: "space-between", alignItems: "center"}}>
                                    {isAddUser ? (
                                        <TextDefault size="25px" color="#282828" bold="800">Adicionar Usuário</TextDefault>
                                    ) : (                            
                                        <TextDefault size="25px" color="#282828" bold="800">Configuração de Usuário</TextDefault>
                                    )}
    
                                    <ImgDefault src={CloseIcon} width="20px" height="20px" style={{marginRight: 30, cursor: "pointer"}} onClick={() => closeModal()}/>
                                </RowContainer>
    
                                <Grid container xs={12} sm={12} md={12} lg={12} style={{marginTop: 40}}>
                                    
                                    <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <TextDefault size={isMobile ? "13px" : "15px"} color="#8a97aa" bold="600" style={{marginBottom: -15}}>Nome</TextDefault>
                                        <GroupInput className="groupInputSale" style={{marginTop: 5}}>
                                            <InputDefault
                                                className="inputSale"
                                                placeholder="Nome"
                                                type="text"
                                                id="nameForm"
                                                value={name}
                                                style={{ paddingLeft: 15, width: "85%" }}
                                                onChange={(item) => setName(item.target.value)}
                                            />                        
                                        </GroupInput>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <TextDefault size={isMobile ? "13px" : "15px"} color="#8a97aa" bold="600" style={{marginBottom: -15}}>Sobrenome</TextDefault>
                                        <GroupInput className="groupInputSale" style={{marginTop: 5}}>
                                            <InputDefault
                                                className="inputSale"
                                                placeholder="Sobrenome"
                                                type="text"
                                                id="lastnameForm"
                                                value={lastName}
                                                style={{ paddingLeft: 15, width: "85%" }}
                                                onChange={(item) => setLastName(item.target.value)}
                                            />                        
                                        </GroupInput>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} style={{marginTop: 40}}>
                                        <TextDefault size={isMobile ? "13px" : "15px"} color="#8a97aa" bold="600" style={{marginBottom: -15}}>E-mail</TextDefault>
                                        <GroupInput className="groupInputSale" style={{marginTop: 5}}>
                                            <InputDefault
                                                className="inputSale"
                                                placeholder="E-mail"
                                                type="email"
                                                id="emailForm"
                                                value={email}
                                                style={{ paddingLeft: 15, width: "85%" }}
                                                onChange={(item) => setEmail(item.target.value)}
                                            />                        
                                        </GroupInput>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} style={{marginTop: 40}}>
                                        <TextDefault size={isMobile ? "13px" : "15px"} color="#8a97aa" bold="600" style={{marginBottom: -15}}>Papel</TextDefault>
                                        <GroupInput className="groupInputSale" style={{marginTop: 5}}>
                                            <SelectDefault
                                                className="inputSale"
                                                placeholder="Papel"
                                                type="text"
                                                id="roleForm"
                                                value={role}
                                                style={{ paddingLeft: 15, width: "87%" }}
                                                onChange={(item) => setRole(item.target.value)}
                                            >
                                                <option value="">Papel</option>
                                                <option value="Administrador">Administrador</option>
                                                <option value="Colaborador">Colaborador</option>
                                            </SelectDefault>                        
                                        </GroupInput>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} style={{marginTop: 40}}>
                                        <TextDefault size={isMobile ? "13px" : "15px"} color="#8a97aa" bold="600" style={{marginBottom: -15}}>Setor</TextDefault>
                                        <GroupInput className="groupInputSale" style={{marginTop: 5}}>
                                            <InputDefault
                                                className="inputSale"
                                                placeholder="Setor"
                                                type="text"
                                                id="setorForm"
                                                value={sector}
                                                style={{ paddingLeft: 15, width: "85%" }}
                                                onChange={(item) => setSector(item.target.value)}
                                            />                        
                                        </GroupInput>
                                    </Grid>
    
                                </Grid>
    
                                <RowContainer style={{position: "absolute", bottom: -50, right: -10}}>
                                    <BtnCancelUser>
                                        <TextDefault size="19px" color="#fff" bold="700">Excluir Usuário</TextDefault>
                                    </BtnCancelUser>
    
                                    <BtnSaveUser style={{marginRight: 90, marginLeft: 15}} onClick={createUser}>
                                        <TextDefault size="19px" color="#fff" bold="700">Salvar</TextDefault>
                                    </BtnSaveUser>
                                </RowContainer>
    
    
                            </ColumnContainer>
                        )}


                    </ModalUsers>
                </ContainerModalUsers>

            </Modal>

            <ColumnContainer style={{paddingLeft: isMobile ? 20 : 120, paddingTop: 30, paddingRight: 40, width: "100%"}}>
                <RowContainer style={{justifyContent: "space-between", alignItems: "center", width: "99%"}}>
                    <TextDefault size="40px" color="#34C576" bold="800" >Gestão de Usuários</TextDefault>
                    
                    <RowContainer style={{alignItems: "center"}}>
                        <ImgDefault src={UserIcon} height="20px" width="20px"/>
                        <TextDefault size="20px" color="#ABABAB" bold="800" style={{marginLeft: 20}}>Emanuel Ferreira</TextDefault>
                    </RowContainer>
                </RowContainer>        

                <RowContainer>
                    <BtnAddUsers mobile={isMobile} style={{marginTop: 20, marginRight: 20}} onClick={() => openModal(0)}>
                        <TextDefault color="#fff" size="18px" bold="800">Adicionar +</TextDefault>
                    </BtnAddUsers>

                    <GroupInput className="groupInputSale">
                        <InputDefault
                            className="inputSale"
                            placeholder="Buscar"                            
                            type="text"
                            id="searchUser"
                            value={search}
                            style={{ paddingLeft: 15, width: "100%" }}
                            onChange={(item) => setSearch(item.target.value)}
                        />
                        <ImgOpt className="iconInputHide" src={Lupa} style={{width: 20, height: 20}}/>
                    </GroupInput>

                </RowContainer>
                
                <RowContainer style={{width: isMobile ? "107%" : "98.9%", marginTop: 30}}>
                    <TableUser data={userList}/>
                </RowContainer>

                <RodapeTable mobile={isMobile}>
                    <TextDefault size={isMobile ? "15px" : "17px"} color="#fff">{userList.length} Usuários registrados</TextDefault>
                </RodapeTable>

                {isMobile ? (
                    <>
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    </>
                ) : ""}

            </ColumnContainer>

        </Container>        
    )
}