import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyD3lZ0pC-j2VQy9QnSMlGKcJm32OXZQ8gI",
    authDomain: "grupo-tondini.firebaseapp.com",
    databaseURL: "https://grupo-tondini-default-rtdb.firebaseio.com",
    projectId: "grupo-tondini",
    storageBucket: "grupo-tondini.appspot.com",
    messagingSenderId: "36438300062",
    appId: "1:36438300062:web:72c75d70d4396fbeda728f",
    measurementId: "G-9MZHHHR024"
  };

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth();
export const database = firebase.database();
