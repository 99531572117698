import React, { useEffect, useState } from "react";
import { ColumnContainer, Container, GroupInput, PainelCR1, RowContainer, SelectDefault, TextDefault } from "../../styles";
import Sidebar from "../../components/sidebar";
import SidebarMobile from "../../components/sidebar/mobile";
import { Grid } from "@mui/material";

export default function RegistroRequisitos() {
    const [isMobile, setIsMobile] = useState("")
    const [isSelected, setIsSelected] = useState("")


    useEffect(() => {
        const TitlePage = "Resgistro da Norma";
        document.title = TitlePage;

        setIsMobile(/Mobi/.test(navigator.userAgent));
    }, []);


    return(
        <Container>
            {isMobile ? (<SidebarMobile/>) : (<Sidebar/>)}

            <ColumnContainer style={{paddingLeft: isMobile ? 20 : 120, paddingTop: 30, paddingRight: 40, width: "100%", height: "65%"}}>
                <RowContainer style={{justifyContent: "space-between", alignItems: "center", width: "99%"}}>
                    <TextDefault size="40px" color="#34C576" bold="800" >Cadastro de Requisitos</TextDefault>                                        
                </RowContainer>

                <TextDefault size="25px" color="#282828" style={{marginTop: 40}}>Seleção da Norma</TextDefault>

                <Grid container xs={12} sm={12} md={12} lg={12} style={{marginTop: 10}}>
                                    
                    <Grid item xs={4} sm={4} md={4} lg={4}>                        
                        <GroupInput className="groupInputSale" style={{marginTop: 5}}>
                            <SelectDefault
                                className="inputSale"
                                placeholder="Selecionar Norma"
                                type="text"
                                id="selectNorma"
                                value={isSelected}
                                style={{ paddingLeft: 15, width: "88%" }}
                                onChange={(item) => setIsSelected(item.target.value)}
                            >
                                <option value="">Selecionar norma</option>
                            </SelectDefault>                        
                        </GroupInput>
                    </Grid>
                </Grid>


                <PainelCR1>
                    <Grid container xs={12} sm={12} md={12} lg={12}>

                        <Grid item xs={12/5} sm={12/5} md={12/5} lg={12/5}>
                            <ColumnContainer>
                                <TextDefault color="#ababab" bold="800" size="20px">Tipo de Documento</TextDefault>
                                <TextDefault color="#ababab" bold="400" size="15px" style={{marginTop: 10}}>Selecione a norma...</TextDefault>
                            </ColumnContainer>
                        </Grid>                     
                        <Grid item xs={12/5} sm={12/5} md={12/5} lg={12/5}>
                            <ColumnContainer>
                                <TextDefault color="#ababab" bold="800" size="20px">Identificador</TextDefault>
                                <TextDefault color="#ababab" bold="400" size="15px" style={{marginTop: 10}}>Selecione a norma...</TextDefault>
                            </ColumnContainer>
                        </Grid>                     
                        <Grid item xs={12/5} sm={12/5} md={12/5} lg={12/5}>
                            <ColumnContainer>
                                <TextDefault color="#ababab" bold="800" size="20px">Revisão /Versão</TextDefault>
                                <TextDefault color="#ababab" bold="400" size="15px" style={{marginTop: 10}}>Selecione a norma...</TextDefault>
                            </ColumnContainer>
                        </Grid>                     
                        <Grid item xs={12/5} sm={12/5} md={12/5} lg={12/5}>
                            <ColumnContainer>
                                <TextDefault color="#ababab" bold="800" size="20px">Organismo Responsável</TextDefault>
                                <TextDefault color="#ababab" bold="400" size="15px" style={{marginTop: 10}}>Selecione a norma...</TextDefault>
                            </ColumnContainer>
                        </Grid>                     
                        <Grid item xs={12/5} sm={12/5} md={12/5} lg={12/5}>
                            <ColumnContainer>
                                <TextDefault color="#ababab" bold="800" size="20px">Data de Emissão</TextDefault>
                                <TextDefault color="#ababab" bold="400" size="15px" style={{marginTop: 10}}>Selecione a norma...</TextDefault>
                            </ColumnContainer>
                        </Grid>                     

                    </Grid>
                </PainelCR1>

                <PainelCR1 style={{marginTop: 30}}>
                    <Grid container xs={12} sm={12} md={12} lg={12}>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <ColumnContainer>
                                <TextDefault color="#ababab" bold="800" size="20px">Título/Descrição da Norma</TextDefault>
                                <TextDefault color="#ababab" bold="400" size="15px" style={{marginTop: 10}}>Selecione a norma...</TextDefault>
                            </ColumnContainer>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: 30}}>
                            <ColumnContainer>
                                <TextDefault color="#ababab" bold="800" size="20px">Resumo do Escopo da Norma</TextDefault>
                                <TextDefault color="#ababab" bold="400" size="15px" style={{marginTop: 10}}>Selecione a norma...</TextDefault>
                            </ColumnContainer>
                        </Grid>
                    </Grid>
                </PainelCR1>

            </ColumnContainer>

        </Container>
    )
}