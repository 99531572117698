import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../pages/login"
import Home from "../pages/home"
import UsersControl from "../pages/usersControl"
import Conected from "../components/conected";
import RegistroNorma from "../pages/registroNorma";
import RegistroRequisitos from "../pages/registroRequisitos";

export const AppRoutes = () => {
    return(
        <Router>
            <Conected>
                <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/users-control" element={<UsersControl/>}/>
                    <Route path="/registro-da-norma" element={<RegistroNorma/>}/>
                    <Route path="/registro-de-requisitos" element={<RegistroRequisitos/>}/>
                </Routes>
            </Conected>
        </Router>
    )
}