import React from "react";
import { authScreen } from "../../contexts/auth";
import { useLocation, useNavigate } from "react-router-dom";

export default function Conected({children}) {

    const navigate = useNavigate();
    const location = useLocation();
    const routeNow = location.pathname;

    authScreen().then((resp) => {
        if(!resp) {
            if (routeNow !== "/") navigate("/")
        } else {
            if(routeNow == "/") navigate("/home")
        }
    })
    return(<>{children}</>)
}