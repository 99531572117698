import { auth, database } from "../database/config"

export async function getUsers() {
    return new Promise(async(resolve, reject) => {
        let array = []
        await database.ref("Companies").child("Agrobiotech").child("Users").once("value", (snapshot) => {
            snapshot.forEach((item) => {
                let data = {
                    key: item.key,
                    name: item.val().name,
                    role: item.val().role,
                    email: item.val().email,
                    lastName: item.val().lastName,
                }
                array.push(data)
            })
        })

        if(array.length > 0) {
            resolve(array)
        } else {
            reject("empty")
        }
    })
};

export function registerUser(user) {

   console.log("Function Hook") 
   console.log(user)

   return new Promise(async(resolve, reject) => {

       const name = user.name;
       const lastName = user.lastName;
       const email = user.email;
       const role = user.role;
       const sector = user.sector;
       const password = gerarSenha()
    
       try {
    
           const userCredential = await auth.createUserWithEmailAndPassword(
            email,
            password
          );
        
          const uid = userCredential.user.uid;
        
          await database.ref("Companies").child("Agrobiotech").child("Users").child(uid).set({
            name,
            lastName,
            email,
            role,
            sector,    
          })
       } catch(e) {
        console.log("Error: ",e)
       }
    
       resolve(password);
   })

}

function gerarSenha() {
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&';
    let senha = '';
    for (let i = 0; i < 8; i++) {
        senha += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }
    return senha;
}

